<template>
  <vh-radio-group v-model="val" @change="change">
    <slot></slot>
  </vh-radio-group>
</template>

<script>
  export default {
    name: 'radioGroup',
    data() {
      return {
        val: ''
      };
    },
    props: {
      value: {
        type: [String, Number],
        default: ''
      }
    },
    watch: {
      value() {
        this.val = this.value;
      }
    },
    methods: {
      change(val) {
        this.$emit('update:value', val);
      }
    }
  };
</script>

<template>
  <!-- 弹框 -->
  <VhallDialog
    title="商品列表"
    :visible.sync="dialogProductList"
    :close-on-click-modal="false"
    class="products-dialog"
    width="600px"
  >
    <vh-table
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      max-height="500"
      :header-cell-style="{ background: '#f7f7f7', color: '#666', height: '56px' }"
    >
      <vh-table-column label="图片">
        <template slot-scope="scope">
          <div class="flexBox">
            <div class="gift-cover">
              <img :src="scope.row.cover_img" alt="" />
            </div>
          </div>
        </template>
      </vh-table-column>
      <vh-table-column label="名称" prop="name" show-overflow-tooltip></vh-table-column>
      <vh-table-column label="原价" prop="price" width="100">
        <template slot-scope="scope">
          <p>
            {{ scope.row.price !== null ? `￥${scope.row.price}` : `--` }}
          </p>
        </template>
      </vh-table-column>
      <vh-table-column label="优惠价" prop="discount_price" width="100">
        <template slot-scope="scope">
          <p>
            {{ scope.row.discount_price !== null ? `￥${scope.row.discount_price}` : `--` }}
          </p>
        </template>
      </vh-table-column>
    </vh-table>
  </VhallDialog>
</template>

<script>
  export default {
    name: 'CouponGoodsList',
    props: {
      couponId: {
        type: [String, Number],
        default: ''
      }
    },
    data() {
      return {
        tableData: [], // 列表数据
        total: 0, // 列表总数
        dialogProductList: false
      };
    },
    components: {},
    methods: {
      async getList() {
        const params = {
          coupon_id: this.couponId
        };
        let res = await this.$fetch('getListCouponGoods', params);
        if (res.code == 200 && res.data) {
          this.total = res.data.total;
          this.tableData = res.data.list;
        }
      },

      async open() {
        await this.getList();
        this.dialogProductList = true;
      }
    }
  };
</script>

<style lang="less" scoped>
  .products-dialog {
    .flexBox {
      display: flex;
      align-items: center;
      .name {
        margin-left: 10px;
      }
      .gift-cover {
        display: inline-block;
        width: 70px;
        min-width: 70px;
        height: 70px;
        // margin: 3px 0;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e6e6e6;
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }
    }
  }
</style>

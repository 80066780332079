<template>
  <div class="coupon-material">
    <vh-dialog
      :title="scene == 'lottery' ? '添加优惠券' : '选择优惠券'"
      :before-close="handleClose"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="820px"
    >
      <div class="coupon-material-list">
        <div v-if="isSearch || total">
          <div class="coupon-material-header">
            <vh-button
              v-if="!isEmbed && scene != 'lottery'"
              type="primary"
              size="medium"
              round
              @click="toCreate"
            >
              创建优惠券
            </vh-button>
            <vh-input
              class="fr"
              round
              size="medium"
              style="width: 180px"
              placeholder="请输入优惠券名称"
              v-clearEmoij
              :maxlength="50"
              v-model="keyword"
              @keyup.enter.native="getList($event, true)"
              clearable
              @clear="getList(true)"
            >
              <i
                class="vh-icon-search vh-input__icon"
                slot="prefix"
                @click="getList($event, true)"
              ></i>
            </vh-input>
          </div>
          <component
            :is="choseType == 'checkbox' ? 'div' : 'radioGroup'"
            :value.sync="currentRadio"
          >
            <!-- 表格 -->
            <vh-table
              ref="elTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              height="340px"
              v-loadMore="moreLoadData"
              @selection-change="changeDialogCheck"
              @select-all="checkAllRow"
            >
              <vh-table-column
                v-if="choseType == 'checkbox'"
                type="selection"
                width="55"
                align="left"
              />
              <vh-table-column v-else width="55">
                <template slot-scope="scope">
                  <vh-radio :label="scope.row.coupon_id"></vh-radio>
                </template>
              </vh-table-column>
              <vh-table-column
                label="名称"
                prop="coupon_name"
                width="150"
                show-overflow-tooltip
              ></vh-table-column>
              <vh-table-column label="类型">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.coupon_type | typeFilter }}
                  </span>
                </template>
              </vh-table-column>
              <vh-table-column label="优惠内容" width="150">
                <template slot-scope="scope">
                  <span v-if="scope.row.coupon_type == 0">
                    满{{ scope.row.threshold_amount }}减{{ scope.row.deduction_amount }}
                  </span>
                  <span v-if="scope.row.coupon_type == 1">
                    无门槛减{{ scope.row.deduction_amount }}
                  </span>
                </template>
              </vh-table-column>
              <vh-table-column label="优惠商品" width="130">
                <template slot-scope="scope">
                  <span
                    :class="{ highLight: scope.row.applicable_product_type != 0 }"
                    @click="openGoodsList(scope.row)"
                  >
                    {{ scope.row.applicable_product_type | productFilter }}
                    {{ scope.row.applicable_product_type != 0 ? scope.row.goods_num : '' }}
                  </span>
                </template>
              </vh-table-column>
              <vh-table-column label="使用时间" width="180">
                <template slot-scope="scope">
                  <div v-if="scope.row.validity_type == 0">
                    <p>起{{ scope.row.validity_start_time }}</p>
                    <p>至{{ scope.row.validity_end_time }}</p>
                  </div>
                  <span v-if="scope.row.validity_type == 1">
                    自领取起{{ scope.row.validity_day }}天内有效
                  </span>
                </template>
              </vh-table-column>
              <div slot="empty">
                <null-page :nullType="'search'" v-if="!total" :height="60"></null-page>
              </div>
            </vh-table>
          </component>
        </div>
        <null-page
          :nullType="'nullData'"
          :text="this.selectTotal ? '您已选择所有优惠券，请创建' : '您还没有优惠券，快来创建吧！'"
          v-else
          :height="60"
        >
          <vh-button v-if="!isEmbed" type="primary" round @click="toCreate" v-preventReClick>
            创建优惠券
          </vh-button>
        </null-page>
        <div class="coupon-material-footer" v-if="isSearch || total">
          <span class="footer-desc">
            当前选中
            <b>{{ choseType == 'checkbox' ? selectCoupons.length : currentRadio ? 1 : 0 }}</b>
            个优惠券
          </span>
          <div>
            <vh-button plain round type="info" size="medium" @click="cancel">取消</vh-button>
            <vh-button
              v-preventReClick
              type="primary"
              round
              size="medium"
              @click="saveCheckHandle"
              :disabled="
                choseType == 'checkbox'
                  ? !(selectCoupons && selectCoupons.length > 0)
                  : !currentRadio
              "
            >
              确定
            </vh-button>
          </div>
        </div>
      </div>
    </vh-dialog>
    <Goods ref="goods" :couponId="curCouponId"></Goods>
  </div>
</template>

<script>
  import NullPage from '../../PlatformModule/Error/nullPage.vue';
  import { isEmbed } from '@/utils/utils';
  import radioGroup from './radioGroup.vue';
  import Goods from './goods.vue';
  export default {
    name: 'CouponMaterial',
    components: {
      NullPage,
      radioGroup,
      Goods
    },
    props: {
      scene: {
        type: String,
        default: ''
      },
      source: {
        type: String,
        default: 'webinar'
      },
      selectedIds: {
        type: Array,
        default: () => {
          return [];
        }
      },
      choseType: {
        type: String,
        default: 'checkbox' // radio
      },
      needBind: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        selectTotal: 0,
        dialogVisible: false,
        total: 0,
        isSearch: false,
        isCheckAll: false,
        tableData: [],
        selectCoupons: [],
        keyword: '',
        pageInfo: {
          page: 1,
          limit: 10
        },
        totalPages: 0,
        isEmbed: isEmbed(),
        currentRadio: '',
        curCouponId: ''
      };
    },
    filters: {
      typeFilter(val) {
        const map = {
          0: '满减券',
          1: '无门槛'
        };
        return map[val];
      },
      productFilter(val) {
        const map = {
          0: '全部商品',
          1: '可用商品',
          2: '不可用商品'
        };
        return map[val];
      }
    },
    watch: {
      selectCoupons(newVal, oldVal) {
        this.$nextTick(() => {
          // this.readonlyTable();
        });
      }
    },
    methods: {
      // 跳转到创建优惠券
      toCreate() {
        const { href } = this.$router.resolve({
          path: `${isEmbed() ? '/embed' : ''}/interactive/addCoupon`
        });
        window.open(href, '_blank');
      },
      handleClose(done) {
        this.pageInfo.page = 1;
        this.tableData = [];
        done();
      },
      moreLoadData() {
        if (this.pageInfo.page >= this.totalPages) {
          return false;
        }
        this.pageInfo.page++;
        this.getList();
      },
      // 获取资料库-弹出框内容
      getList(isClear, isInit) {
        this.isSearch = this.keyword ? true : false;
        if (isClear) {
          this.tableData = [];
          this.pageInfo.page = 1;
        }
        let params = {
          pos: (this.pageInfo.page - 1) * this.pageInfo.limit,
          limit: this.pageInfo.limit,
          keyword: this.keyword
        };
        let requestObj = null;
        let headers = {};
        if (this.source == 'webinar') {
          params.webinar_id = this.$route.params.str;
          requestObj = 'getWebinarListCoupon';
          headers = {
            'Content-Type': 'application/json'
          };
          if (this.scene == 'lottery') {
            params.lottery_id = this.$route.query.lotteryId;
            requestObj = 'lotteryCouponList';
            headers = {};
          }
        } else {
          requestObj = 'getListCoupon';
          params.webinar_id = this.$route.params.str;
          headers = {
            'Content-Type': 'application/json'
          };
        }

        this.$fetch(requestObj, params, headers)
          .then(res => {
            if (res && res.code === 200) {
              if (this.choseType == 'checkbox') {
                if (isInit) {
                  if (this.selectedIds.length) {
                    this.selectCoupons = this.selectedIds;
                  }
                } else {
                  const diff = this.selectedIds.filter(item => {
                    return !this.tableData.some(i => {
                      return item == i.coupon_id;
                    });
                  });
                  this.selectCoupons = this.selectCoupons.concat(...diff);
                }
              }

              this.tableData.push(...(res.data.list || []));
              this.total = res.data.total;
              this.totalPages = Math.ceil(res.data.total / this.pageInfo.limit);
              if (this.isCheckAll) {
                this.$refs.elTable.toggleAllSelection();
              }
            } else {
              this.$vhMessage.error(res.msg);
            }
          })
          .finally(() => {
            if (this.choseType == 'checkbox') {
              // this.readonlyTable();
            }
          });
      },
      // 改变资料库-弹出框内容
      changeDialogCheck(val) {
        console.log(val);
        this.selectCoupons = val.map(item => item.coupon_id);
      },
      checkAllRow(selection) {
        console.log('全选与非全选', selection);
        // 只要数量大于0，即是够了全选
        this.isCheckAll = selection && selection.length > 0;
      },
      open() {
        // 历史已经选择过的数据清空
        this.tableData = [];
        this.isSearch = false;
        this.dialogVisible = true;
        this.isCheckAll = false;
        this.keyword = '';
        this.pageInfo = {
          page: 1,
          limit: 10
        };
        this.getList(true, true);
      },
      saveCheckHandle() {
        let res = null;
        if (this.choseType == 'checkbox') {
          if (this.selectCoupons.length + this.selectTotal > 200) {
            this.$vhMessage.error('最多选择200个优惠券');
            return;
          }
          res = this.tableData.filter(item => {
            return this.selectCoupons.some(i => {
              return item.coupon_id == i;
            });
          });
        } else {
          this.tableData.map(item => {
            if (item.coupon_id == this.currentRadio) {
              res = [item];
            }
          });
        }
        if (this.needBind) {
          //资料库选择
          console.log('资料库选择');
          let ids = res.map(item => {
            return item.coupon_id;
          });
          this.$fetch(
            'addWebinarCoupon',
            {
              webinar_id: this.$route.params.str,
              coupon_ids: ids
            },
            {
              'Content-Type': 'application/json'
            }
          )
            .then(res2 => {
              if (res2.code == 200) {
                // window.location.reload();
                // this.getList('clearPage');
                this.$emit('selected', res);
                this.cancel();
              }
            })
            .catch(res => {
              this.$vhMessage({
                message: res.message || `保存失败`,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
              this.open();
            });
        } else {
          this.$emit('selected', res);
          this.cancel();
          // let ids = res.map(item => {
          //   return item.coupon_id;
          // });
          // this.$fetch(
          //   'checkCoupons',
          //   {
          //     webinar_id: this.$route.params.str,
          //     coupon_ids: ids
          //   },
          //   {
          //     'Content-Type': 'application/json'
          //   }
          // )
          //   .then(res2 => {
          //     if (res2 && res2.code === 200) {
          //       let obj = res;
          //       if (res2.data) {
          //         obj = res.filter(e => {
          //           return res2.data.includes(parseInt(e.coupon_id + ''));
          //         });
          //       }

          //       this.$emit('selected', obj);
          //       this.cancel();
          //     }
          //   })
          //   .catch(res => {
          //     this.$vhMessage({
          //       message: res.message || `保存失败`,
          //       showClose: true,
          //       // duration: 0,
          //       type: 'error',
          //       customClass: 'zdy-info-box'
          //     });
          //     this.open();
          //   });
        }
      },
      cancel() {
        this.selectCoupons = [];
        this.currentRadio = '';
        try {
          this.$refs.elTable.clearSelection();
        } catch (e) {
          console.log(e);
        }
        document.querySelector('.coupon-material .vh-table__body-wrapper').scrollTop = 0;
        this.dialogVisible = false;
      },
      // readonlyTable() {
      //   if (this.tableData.length > 0 && this.selectCoupons.length > 0) {
      //     for (let i = 0; i < this.tableData.length; i++) {
      //       if (this.selectCoupons.includes(this.tableData[i].coupon_id)) {
      //         this.$nextTick(() => {
      //           this.$refs.elTable.toggleRowSelection(this.tableData[i], true);
      //         });
      //       }
      //     }
      //   }
      // },
      openGoodsList(row) {
        if (row.applicable_product_type != 0) {
          this.curCouponId = parseInt(row.coupon_id + '');
          this.$nextTick(() => {
            this.$refs.goods.open();
          });
        }
      }
    }
  };
</script>

<style lang="less">
  .coupon-material {
    &-list {
      min-height: 378px;
      .vh-radio-group {
        width: 100%;
        max-width: 100%;
      }
      .vh-radio__label {
        display: none;
      }
      .vh-table td:first-child,
      .vh-table th:first-child {
        padding-left: 24px;
      }
    }
    &-header {
      padding-bottom: 24px;
      overflow: hidden;
    }
    .coupon-cover {
      width: 70px;
      height: 70px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &-footer {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      .footer-desc {
        line-height: 36px;
        color: #262626;
      }
      b {
        color: #fb2626;
      }
    }
    .highLight {
      color: #0a7ff5;
      cursor: pointer;
    }
  }
</style>
